import React, { useState, useEffect } from 'react'; //we already import useState and useEffect that we will use later
import * as queryString from "query-string";
import { StaticImage } from "gatsby-plugin-image"

import Seo from "../components/seo"

import "./loading.scss"

import EggCursor from "../components/eggcursor";

const Loading = ({location}) => {
  const loadingStrings = [
    'POACHING...',
    'HIRING YOUR competitors...',
    'WRITING TWEETS...',
    'Share so i don’t get fired...',
    'This is a fake loading bar...',
    'Mining Crypto...',
    'You can close this tab...',
    'Listening to Gunna...'
  ];


  let { handle } = queryString.parse(location.search);
  if (!handle) handle = "partyround";
  if (!handle.startsWith("@")) handle = "@" + handle;

  const shareUrl = 'https://twitter.com/intent/tweet?url=https%3A%2F%2Fpoached.xyz&text=Hire%20'+handle+'\'s%20followers%20using%20the%20latest%20%40PartyRound%20drop.'


  const [strCount, setStrCount] = useState(0);

  useEffect(() => {
    setInterval(() => {
      let s = 0;
      setStrCount((count) => {s = count; return count;}); 
      if (s >= 7) setStrCount(0);
      else setStrCount(prevCount => prevCount + 1);
    }, 5000);
  }, []);

  return (
    <>
      <Seo/>
      <main className="loading-main">
        <EggCursor />
        <div className="position-absolute loading-links">
          <a href="/" target="_blank" rel="noreferrer">
            <StaticImage src="../images/results_btn-home.png" alt="Home" placeholder="none" width={100}/>
          </a>
          <a href={shareUrl} target="_blank" rel="noreferrer">
            <StaticImage src="../images/results_btn-share.png" alt="Home" placeholder="none" width={100}/>
          </a>
        </div>
        <div className="loading container d-flex flex-column justify-content-center align-items-center">
          <h1 className='text-center'>{loadingStrings[strCount]}</h1>
          <div className="loading-bar">
            <StaticImage className="loading-block" src="../images/loading-block.png" alt="Share" placeholder="none" width={92}/>
            <StaticImage className="loading-block loading-hide" src="../images/loading-block.png" alt="Share" placeholder="none" width={92}/>
            <StaticImage className="loading-block loading-hide" src="../images/loading-block.png" alt="Share" placeholder="none" width={92}/>
            <StaticImage className="loading-block loading-hide" src="../images/loading-block.png" alt="Share" placeholder="none" width={92}/>
            <StaticImage className="loading-block blink" src="../images/loading-block.png" alt="Share" placeholder="none" width={92}/>

          </div>
          <p>Our bot is hard at work. Feel free to close this tab, and it will email you when done!</p>
        </div>
      </main>
    </>
  )
}

export default Loading